import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

import TopHeader from '../components/TopHeader';
import Footer from "../screens/Footer";
// import moonImage from '../resources/assets/Images/moon.webp';
// import earthImage from '../resources/assets/Images/earth.webp';
// import sicons1 from '../resources/assets/Images/sicons1.webp';
// import sicons2 from '../resources/assets/Images/sicons2.webp';
// import sicons3 from '../resources/assets/Images/sicons3.webp';
// import sicons4 from '../resources/assets/Images/sicons4.webp';
// import sicons5 from '../resources/assets/Images/sicons5.webp';
// import sicons6 from '../resources/assets/Images/sicons6.webp';
// import robo from '../resources/assets/Images/robo.png';
import EventDataParser from "../Services/EventDataParser";
import './Activities.css';
import { BUCKET_URL } from "../connection";


function Activities() {
    const [isMobile, SetisMobile] = useState(window.innerWidth <= 600);
    const [gameData, setGameData] = useState([]);
    const moonImage = BUCKET_URL + "moon.webp";
    const earthImage = BUCKET_URL + "earth.webp";
    const sicons1 = BUCKET_URL + "sicons1.webp";
    const sicons2 = BUCKET_URL + "sicons2.webp";
    const sicons3 = BUCKET_URL + "sicons3.webp";
    const sicons4 = BUCKET_URL + "sicons4.webp";
    const sicons5 = BUCKET_URL + "sicons5.webp";
    const sicons6 = BUCKET_URL + "sicons6.webp";
    const robo = BUCKET_URL + "robo.png";

    React.useEffect(() => {
        if (gameData.length <= 0)
            getTileData();
    }, []);


    async function getTileData() {
        let data = await EventDataParser();
        setGameData(data);
        // console.log(data);

    }

    React.useEffect(() => {
        const handleResize = () => {
            SetisMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const responsiveIframe = {
        width: isMobile ? '100%' : '44rem',
        height: isMobile ? '15rem' : '25rem',
        border: 'none',
        borderRadius: '1.25rem',
        overflow: 'hidden',
    };

    const styledVideoContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        maxWidth: isMobile ? '100%' : '50rem',
        width: '100%',
        position: 'relative',
    };

    return (
        <>
            <TopHeader />

            <div className="DashboardDiv" style={{}}>
                <div className="bgOverlayImage" style={{}}>
                    <img src={moonImage} alt="" className="moonImage" />
                    <img src={earthImage} alt="" className="earthImage" />
                </div>

                <Container className="mainDiv" >


                    <div className="gridContainerDiv" style={{}}>
                        <Grid container style={{ justifyContent: 'center', alignItems: 'center', zIndex: '1' }}>
                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/artconnect" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons1} alt="e" />
                                        <h3 style={{}}>Art Connect</h3>

                                    </div>
                                </NavLink>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/quiz" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons2} alt="e" />
                                        <h3 style={{}}>Quiz</h3>

                                    </div>
                                </NavLink>
                            </Grid>

                        </Grid>
                        <Grid container style={{ justifyContent: 'center', alignItems: 'center', zIndex: '1' }}>
                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/picturePuzzle" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons3} alt="e" />
                                        <h3 style={{}}>Picture Puzzle</h3>

                                    </div>
                                </NavLink>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} xl={3} lg={3} className="activitiesGrid" style={{}}>
                                <div style={{ margin: '0 auto' }}>
                                    <img style={{ width: '100%' }} src={robo} alt="e" />

                                </div>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/puzzle" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons4} alt="e" />
                                        <h3 style={{}}>Picture Builder</h3>

                                    </div>
                                </NavLink>
                            </Grid>

                        </Grid>
                        <Grid container style={{ justifyContent: 'center', alignItems: 'center', zIndex: '1' }}>
                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/novels" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons5} alt="e" />
                                        <h3 style={{}}>Graphic Novels</h3>

                                    </div>
                                </NavLink>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className="activitiesGrid" style={{}}>
                                <NavLink to="/inspiringstories" variant="body2" style={{ textDecoration: 'none' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <img style={{ width: '100%' }} src={sicons6} alt="e" />
                                        {/* <img style={{ width: '100%' }} src="sicons2.webp" alt="e" /> */}
                                        <h3 style={{}}>Special Modules</h3>

                                    </div>
                                </NavLink>
                            </Grid>

                        </Grid>

                    </div>

                    <div style={{ width: '100%', margin: '4% auto', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <NavLink to="/launchevents" variant="body2" style={{ maxWidth: isMobile ? '90%' : '28%' }}>

                            <Button
                                className='fontWeightImp'
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                // style={{

                                //     width: isMobile ? '60%' : '35%',
                                //     margin: '0', textTransform: 'capitalize',
                                //     background: 'linear-gradient(180deg, rgba(180, 131, 180, 1) 0%, rgba(119, 35, 121, 1) 100%)',
                                //     border: '1px solid #fff',
                                //     borderRadius: '20px',
                                // }}
                                style={{
                                    backgroundColor: '#0054A5',
                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                    color: '#fff',
                                    padding: '0.5rem 2.3rem',
                                    border: 'none',
                                    borderRadius: '0.6rem',
                                    fontWeight: '700',
                                    fontSize: '1.3rem',
                                    margin: '0 auto 1.7rem',
                                }}
                            >
                                View the Launch Event <br />e-Resources
                            </Button>

                        </NavLink>
                        <NavLink to="/eresources" variant="body2" style={{ maxWidth: isMobile ? '90%' : '28%' }}>

                            <Button
                                className='fontWeightImp'
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                style={{
                                    backgroundColor: '#0054A5',
                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                    color: '#fff',
                                    padding: '0.5rem 2.3rem',
                                    border: 'none',
                                    borderRadius: '0.6rem',
                                    fontWeight: '700',
                                    fontSize: '1.3rem',
                                    margin: '0 auto 1.7rem',
                                }}
                            >
                                Access Space Day <br />e-Resources of ISRO
                            </Button>

                        </NavLink>
                    </div>


                    <Grid item xs={12} sm={12} md={4} lg={12} xl={12}>
                        <div className="descriptionStyle">
                            <div style={styledVideoContainer}>
                                <iframe
                                    className='launchvideoFrames'
                                    src="https://www.youtube.com/embed/N_ECZ4PExP0?si=J16bUEioc-1e7IaW"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer
                                        autoplay;
                                        clipboard-write;
                                        encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen
                                    style={responsiveIframe}
                                />
                            </div>
                        </div>
                    </Grid>


                </Container>

            </div>
            <Footer />
        </>

    );

}
export default Activities;